@import '~/src/common/css/base/variables';
@import '~/src/common/css/base/mixins';

@import '~/src/common/css/vendor/fontawesome/fontawesome';
@import '~/src/common/css/vendor/fontawesome/solid';

@import 'minigame-toggle';
@import 'minigame-snake';

.minigame-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: rem(40px) 0 rem(20px);

  & > * {
    margin: rem(15px) rem(30px);
  }
}
