@include fa-undo;

#minigame-snake {
  color: $secondary;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.03em;

  .game-container {
    position: relative;
    line-height: 0;
  }

  .overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.2);
    pointer-events: none;
    line-height: 1.4;
  }

  canvas {
    border: 3px solid rgba($secondary, 0.7);
    border-radius: 3px;
    background-color: rgba($primary-accent, 0.1);

    &:focus {
      outline: none;
    }
  }

  .score {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1;
    padding: rem(5px) rem(6px);
    font-size: rem(12px);
  }

  .info {
    line-height: 1.6;
    font-size: rem(12px);
    text-align: center;
  }
}
